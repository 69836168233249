import {useState, useRef, setState, useEffect} from 'react';
import {Route, Link, Routes, useParams} from 'react-router-dom';
function SideMenu() {
	const indexRef = useRef(null)
	const contactRef = useRef(null)
	const adminRef = useRef(null)
	const callsRef = useRef(null)
	const ticketRef = useRef(null)
	const agentRef = useRef(null)
	const params = useParams()
	let ticketUrls = ['/tickets','ticket_details','/filters']
	let indexUrls  = ['/','/getting_started','/forgot_password','/change_password']
	let callUrls  = ['/calls', '/calls#outgoing_call', 'calls#incoming_call', 'calls#purchase_number', '/outgoing_call_rates', 'incoming_call_rates']
	let contactUrls = ['/contacts', '/companies','/contact_groups','/company_groups']
	let agentUrls = ['/agents', '/teams']
	let adminUrls = ['/call_route','/business_hours', '/ivr']

	
	useEffect(()=>{
		let elements = document.getElementsByClassName('submenu');
		for(var i=0;i<elements.length;i++){
			elements[i].style.display='none'
		}
		for(var i=0;i<ticketUrls.length;i++){
			if(window.location.href.endsWith(ticketUrls[i])){
				console.log('inside tickets')
				ticketRef.current.style.display = 'block'
			}
		}
		for(var i=0;i<indexUrls.length;i++){
			if(window.location.href.endsWith(indexUrls[i])){
				indexRef.current.style.display = 'block'
			}
		}
		for(var i=0;i<callUrls.length;i++){
			if(window.location.href.endsWith(callUrls[i])){
				callsRef.current.style.display = 'block'
			}
		}
		for(var i=0;i<agentUrls.length;i++){
			if(window.location.href.endsWith(agentUrls[i])){
				agentRef.current.style.display = 'block'
			}
		}
		for(var i=0;i<contactUrls.length;i++){
			if(window.location.href.endsWith(contactUrls[i])){
				contactRef.current.style.display = 'block'
			}
		}
		for(var i=0;i<adminUrls.length;i++){
			if(window.location.href.endsWith(adminUrls[i])){
				console.log('inside aminRef '+(adminRef.current === null))
				adminRef.current.style.display = 'block'
			}
		}
	})
		
  return (
    
    <nav class="sidebar" >
      <div class="menu_content">
        <ul class="menu_items" style={{marginTop:'20px'}}>
          <div class="menu_title menu_dahsboard"></div>          
          <li class="item" onClick={()=> window.location.href='/getting_started'}>
            <div href="#" class="nav_link submenu_item">
              <span class="navlink_icon">
                <i class="bx bx-home-alt"></i>
              </span>
              <span class="navlink">Getting Started</span>
              <i class="bx bx-chevron-right arrow-left"></i>
            </div>

            <ul class="menu_items submenu" ref={indexRef}>
              <a href="/getting_started" class="nav_link sublink">Access Account</a>
              <a href="/forgot_password" class="nav_link sublink">Forgot Password</a>
              <a href="change_password" class="nav_link sublink">Change Password</a>
            </ul>
          </li>
          
          
          <li class="item" onClick={()=> window.location.href='/tickets'}>
            <div href="#" class="nav_link submenu_item">
              <span class="navlink_icon">
                <i class="bx bx-grid-alt"></i>
              </span>
              <span class="navlink">Tickets</span>
              <i class="bx bx-chevron-right arrow-left"></i>
            </div>

            <ul class="menu_items submenu" ref={ticketRef}>
              <a href="/tickets" class="nav_link sublink">Create Ticket</a>
              <a href="/ticket_details" class="nav_link sublink">Priority & Status</a>
              <a href="/filters" class="nav_link sublink">Filters</a>
            </ul>
          </li>
           <li class="item" onClick={()=> window.location.href='/calls'}>
            <div href="#" class="nav_link submenu_item">
              <span class="navlink_icon">
                <i class="bx bx-cog"></i>
              </span>
              <span class="navlink">Calls</span>
              <i class="bx bx-chevron-right arrow-left"></i>
            </div>

            <ul class="menu_items submenu" ref={callsRef}>
              <a href="/calls#purchase_number" class="nav_link sublink">Purchase Number</a>
              <a href="/calls#incoming_call" class="nav_link sublink">Incoming Call</a>
              <a href="/calls#outgoing_call" class="nav_link sublink">Outgoing Call</a>
              <a href="/incoming_call_rates" class="nav_link sublink">Incoming Call Costs</a>
              <a href="/outgoing_call_rates" class="nav_link sublink">Outgoing Call Costs</a>
            </ul>
          </li>
           <li class="item" onClick={()=> window.location.href='/agents'}>
            <div href="#" class="nav_link submenu_item">
              <span class="navlink_icon">
                <i class="bx bx-loader-circle"></i>
              </span>
              <span class="navlink">Agents</span>
              <i class="bx bx-chevron-right arrow-left"></i>
            </div>

            <ul class="menu_items submenu" ref={agentRef}>
              <a href="/agents" class="nav_link sublink">Agents</a>
              <a href="/teams" class="nav_link sublink">Teams</a>              
            </ul>
          </li>
           <li class="item" onClick={()=> window.location.href='/contacts'}>
            <div href="#" class="nav_link submenu_item ">
              <span class="navlink_icon">
                <i class="bx bx-flag"></i>
              </span>
              <span class="navlink">Contacts</span>
              <i class="bx bx-chevron-right arrow-left"></i>
            </div>

            <ul class="menu_items submenu " ref={contactRef}>
              <a href="/contacts" class="nav_link sublink">Contacts</a>
              <a href="/companies" class="nav_link sublink">Companies</a>
              <a href="contact_groups" class="nav_link sublink">Contact Group</a>
              <a href="company_groups" class="nav_link sublink">Company Group</a>
            </ul>
          </li>
          <li class="item" onClick={()=> window.location.href='/call_route'}>
            <div href="#" class="nav_link submenu_item show_submenu">
              <span class="navlink_icon">
                <i class="bx bx-medal"></i>
              </span>
              <span class="navlink">Admin</span>
              <i class="bx bx-chevron-right arrow-left"></i>
            </div>

            <ul class="menu_items submenu" ref={adminRef}>
              <a href="/call_route" class="nav_link sublink">Call Route</a>
              <a href="/ivr" class="nav_link sublink">IVR</a>
              <a href="/business_hours" class="nav_link sublink">Business Hours</a>
              <a href="/subscriptions" class="nav_link sublink">Subscriptions</a>
            </ul>            
          </li>
        </ul>

        <div class="bottom_content">
          
        </div>
      </div>
    </nav>
  );
}

export default SideMenu;
