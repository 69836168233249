

function CallRoute() {
  return (
  <div>
  		<h3 style={{display:'flex', justifyContent:'center' }}>Call Routes</h3>
  		<p>Call Routes specify how the incoming call should be handled based on pre defined conditions </p>
  		
  		<ol><li>To Configure a call route navigate to <a href='https://app.supportspoc.com/call_routes/create'>Call Route Create</a> Link.
  		A page opens as shown in below screenshot</li>
  		<img src={require('../../img/CallRoute.png')} alt='' style={{height:'400px'}}/>
  		<li>Enter the name for the Route and a <b>Welcome Message</b>.
  		Welcome message will be converted from text to voice and will be played to the caller when the call enters this route.
  		</li>
  		<li>Select the conditions to be applied to the call to route the call.
  			Below are the types of conditions to be applied to the call based on the caller
  			<ul><li>
  				<b>Contact:</b>When the call is received from a specific contact</li>
  				<li><b>Company:</b>When the call is received from any contact belonging to a Company</li>
  				<li><b>Contact Group:</b>When the call is received from any contact belonging to a Contact Group</li>
  				<li><b>Company Group:</b>When the call is received from any contact belonging to a any Company from the list of companies in Company Group</li>
  			</ul>
  			Below are the routes the call is transferred to, if the condition match
  			<ul><li>
  				<b>Agent:</b>Routed to a specific Agent. If the Agent is unavailble at the time, the call will be queued and retried .</li>
  				<li><b>Team:</b>Routed to the available Agents in the specific Team. If the Agents are unavailble at the time of call, the call will be queued and retried .</li>
  				<li><b>All Agents:</b>Routed to all the available Agents . If all the Agents are unavailble at the time of call, the call will be queued and retried .</li>  				
  			</ul>
  		</li>
  		<p>In the value box, type the name of the contact, company, contact group or company group selected in above list.
  		   A dropdown list is displayed below with the values starting with the letter typed. Select the specific value from the list.</p>
  		</ol>
  		<div class="fd-callout-note">
           <strong>Note:</strong>
           <p>Multiple conditons can be created in the call route. The conditions will be processed in the order from top to bottom.
           &nbsp; If no conditons match, then the call will be transferred to all Online Agents</p>
        </div>

  		<br/><br/><br/>
  </div>
  );
}

export default CallRoute;
