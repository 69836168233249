

function BusinessHours() {
  return (    <div class="container">
        <h3 style={{fontWeight: "600",margin:'auto',display:'table'}}>Configuring Business Hours</h3>
        <p>Setting up business hours in your SUPPORTSPOC system ensures that your support team is available to handle customer queries during designated times. This helps in managing customer expectations and improving overall service efficiency.</p>
        <br/>
        <h3 style={{fontWeight: "600",fontSize:'22px'}}>Steps to Configure Business Hours</h3>        
        <ol>
            <li>
                Log in to your <a href='https://app.supportspoc.com'>SUPPORTSPOC</a> account, Click on Admin menu and select Business Hours
            </li>
            <li>Click on <button class='ss-button'>+New Business Hours</button> button on top right side of page<br/>
            A page similar to below image will be opened
            <img src={require('../../img/business-hours.png')} />
            </li>
            <li>Enter the name of the calender </li>
            <li>If the support hours of your business is 24 hours entire week, select the box is 24*7 business. All the input fields for the days will be disbled.</li>
            <li><strong>Define Business Hours:</strong><br/>
                Specify the start and end times for each day of the week. You can set different hours for each day or apply the same hours to multiple days.
                <ul>
                    <li><strong>Monday to Friday:</strong> For example, 9:00 AM to 6:00 PM.</li>
                    <li><strong>Saturday:</strong> For example, 10:00 AM to 2:00 PM.</li>
                    <li><strong>Sunday:</strong> You can leave it blank or set it to "Closed" if your business is not operational on Sundays.</li>
                </ul>
            </li>
            <li>Configure the break timinngs</li>
            <li>Add which timezone the business hours belongs to </li>
            <li>Add holidays to the business hours. This is optional field</li>
            <li><strong>Save Configuration:</strong><br/>
                Once you have defined the business hours, click on "Save" to apply the settings.
            </li>
        </ol>    
        <div class="note">
            <p><strong>Note: Holidays and Break timings are considered for calculating SLA of the tickets and processing incoming calls.</strong></p>
        </div>
    </div>
  );
}

export default BusinessHours;
