

function TicketDetails() {
  return (
            <div class='were'>
    <h3 style={{fontWeight:'1000', display:'flex', justifyContent:'center' }}> Ticket Priorities</h3>
      <p><b>Overview</b></p>
      <p>
      In a support system, ticket priorities are used to categorize the urgency and importance of customer issues. Assigning priorities helps in managing and resolving tickets efficiently, ensuring that critical issues are addressed promptly while less urgent ones are handled appropriately.
      </p>
      <p><b>Priority Levels</b></p>
      <p>
      Most support systems categorize tickets into several priority levels. Here are the commonly used priority levels:
      </p>
      <p>
      <b>Low Priority:</b>
      <ul>
      <li>
      <b>Description:</b> Issues that have minimal impact on the customer's operations or experience.
      </li>
      <li>
      <b>Examples:</b> General inquiries, minor usability issues, feature requests.
      </li>
      <li>
      <b>Response Time: </b>Typically, these tickets are addressed within a few days to a week.
      </li>
      <li>
      <b>Resolution Time:</b> Can be resolved as part of regular maintenance or in future updates.
      </li>
      </ul>
      </p>
      <p>
      <b>Medium Priority:</b>
      <ul><li>
      <b>Description:</b> Issues that have a moderate impact on the customer's operations but are not critical.
      </li>
      <li>
      <b>Examples:</b> Non-urgent bugs, standard technical support requests, moderate usability issues.
      </li>
      <li>
      <b>Response Time:</b> Usually addressed within 24 to 48 hours.
      </li>
      <li>
      <b>Resolution Time:</b> Should be resolved within a few days to a week, depending on complexity.
      </li></ul>
      </p>
      <p>
      <b>High Priority:</b>
      <ul><li>
      <b>Description:</b> Issues that significantly impact the customer's operations and require quick resolution.
      </li>
      <li>
      <b>Examples:</b> Major functionality issues, significant performance degradation, urgent support requests.
      </li>
      <li>
      <b>Response Time:</b> Typically addressed within a few hours.
      </li><li>
      <b>Resolution Time:</b> Should be resolved within 24 to 48 hours.
      </li></ul>
      </p>
      <p>

      <b>Critical Priority:</b>
      <ul><li>
        <b>Description:</b> Issues that cause a complete outage or severe disruption of services, affecting a large number of users or critical operations.
      </li>
      <li>
        <b>Examples:</b> System outages, data breaches, severe security vulnerabilities.
      </li>
      <li>
        <b>Response Time:</b> Immediate response required, typically within an hour.
      </li><li>
        <b>Resolution Time:</b> Should be resolved as quickly as possible, often within hours.
      </li>
      </ul>
      </p>
      <div class="fd-callout-note">
        <strong>Note:</strong>
        <p>We have provided another Prioriy <b>Escalated</b> which can be used to identify customer escalations</p>
      </div>

        <h3 style={{fontWeight:'1000', display:'flex', justifyContent:'left' }}>Ticket Status</h3>
        <p>
        <ul><li>
        <b>Open:</b> This is the initial status of the Ticket when it is created. 
        </li><li>
        <b>Closed:</b> This status indicates that the issues mentioned in the Ticket is resolved and the Ticket is closed.
        </li><li>
        <b>In-Progress:</b> This status can be set when the Ticket is assigned to an Agent and the Agent starts working on the ticket
        </li><li>
        <b>Need Information</b> This status can be  set when the Agent needs more information on the details on the issue from the customer.
        </li><li>
        <b>Blocked</b> This status can be set when the Agent is unable to proceed to resolve the issue because of a dependency on another team, another ticket or any other dependency.
        </li></ul>
        </p>
  </div>
 

  );
}

export default TicketDetails;
