


function TopMenu() {
  return (
<nav class="navbar navbar-expand-lg navbar-light  topmenu" style={{backgroundColor:'white'}}>
  <div class="container">
      <a  href="#" style={{backgroundColor:'white', position:'absolute',left:'10px',height:'50px',width:'150x',padding:'20px'}}><img src={require("../img/logo2.png")} style={{height:'40px'}}/></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
      <ul class="navbar-nav mb-2 mb-lg-0 d-flex">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="https://supportspoc.com">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://supportspoc.com/#products">Products</a>
        </li>
        <li class="nav-item"><a class="nav-link" href="https://supportspoc.com#features">Features </a></li>
        <li class="nav-item"><a class="nav-link" href="https://supportspoc.com/#about">Who We Are</a></li>
         <li class="nav-item"><a class="nav-link" href="https://supportspoc.com/#pricing">Pricing</a></li>
        <li class="nav-item"><a class="nav-link" href="https://supportspoc.com/#contact">Contact</a></li>
        <li class="nav-item"><a class="nav-link" href="https://app.supportspoc.com/login">Login</a></li>
      </ul>
    </div>
  </div>
</nav>
  );
}

export default TopMenu;
